'use client'
import { useEffect, useRef } from 'react'
import { GTMAdBannerViewTracker } from '../[lng]/components/GTMTrackers/GTMAdBannerTracker'

export const useIntersectionObserver = (
  callback: (element: Element) => void,
  options?: IntersectionObserverInit
): ((element: Element | null) => void) => {
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target)
        }
      })
    }, options)

    return () => {
      observer.current?.disconnect()
    }
  }, [callback, options])

  const observe = (element: Element | null) => {
    if (element) {
      observer.current?.observe(element)
    }
  }

  return observe
}

export const handleAdIntersection = (element: Element) => {
  const adPosition: string = element.getAttribute('data-ad-position') || ''
  const adPage: string = element.getAttribute('data-ad-page') || ''
  const adId: string = element.getAttribute('data-ad-id') || ''
  const adLang: string = element.getAttribute('data-ad-lang') || ''
  const adCompanyId: string = element.getAttribute('data-ad-company-id') || ''
  if (adId) {
    GTMAdBannerViewTracker({
      ad_position: adPosition,
      ad_id: adId,
      ad_lang: adLang,
      ad_company_id: adCompanyId,
      ad_page: adPage,
    })
  }
}
