import React from 'react'
import { useTranslation } from '@/app/i18n/client'
import { toast } from 'sonner'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import Image from 'next/image'

const ShareSocials = ({
  sharAbleLink,
  shareText,
  shareBg,
}: {
  sharAbleLink: string
  shareText: string
  shareBg?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div
          className={`cursor-pointer bg-footer_color text-gray text-[11px] flex items-center gap-1.5  ${shareBg ? ' rounded-[3px] px-1.5 mobile:p-2' : 'rounded-full p-1.5'}`}
        >
          <Image
            priority
            src="/assets/Icons/Share.svg"
            alt="share"
            height={25}
            width={25}
            className=" cursor-pointer w-[11px] h-[11px] "
          />
          {shareBg && (
            <span className="mobile:hidden relative">{t('Share')}</span>
          )}
        </div>
      </DialogTrigger>
      <DialogContent
        className="  bg-[white]  p-5  w-[550px]"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className=" flex   w-full rounded-xl  ">
          <div className=" w-full mx-4 p-4 ">
            {/* <!--MODAL HEADER--> */}
            <div className="flex justify-between items center border-b border-gray-200 w-full">
              <div className="flex items-center justify-center">
                <p className="text-xl font-bold text-gray-800">{t('Share')}</p>
              </div>
            </div>

            {/* <!--MODAL BODY--> */}
            <div className="my-4">
              <p className="text-sm">{t('Share this link via')}</p>

              <div className="flex justify-around my-4">
                {/* <!--FACEBOOK ICON--> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://web.facebook.com/sharer.php?u=${sharAbleLink ?? ''}`}
                  className="border hover:bg-[#1877f2] w-12 h-12 fill-[#1877f2] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-blue-500/50 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                  </svg>
                </a>
                {/* <!--TWITTER ICON--> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://twitter.com/intent/tweet?text=${shareText}&url=${sharAbleLink ?? ''}`}
                  className="border hover:bg-[#1d9bf0] w-12 h-12 fill-[#1d9bf0] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
                >
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    className="h-[31px]"
                  >
                    <g>
                      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                    </g>
                  </svg>
                </a>
                {/* <!--REDDIT ICON--> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://www.reddit.com/submit?url=${sharAbleLink ?? ''}&title=${shareText ?? ''}`}
                  className="border hover:bg-[red] w-12 h-12 fill-[red] hover:fill-white border-pink-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-pink-500/50 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="256"
                    height="256"
                    viewBox="0 0 256 256"
                  >
                    <g
                      fill="none"
                      strokeMiterlimit="10"
                      strokeWidth="0"
                      transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                    >
                      <circle cx="45" cy="45" r="45" fill="#FF4500"></circle>
                      <path
                        fill="#FFF"
                        d="M75.011 45c-.134-3.624-3.177-6.454-6.812-6.331a6.594 6.594 0 00-4.306 1.823 31.99 31.99 0 00-17.327-5.537l2.919-14.038 9.631 2.025a4.497 4.497 0 004.955 3.993c2.472-.268 4.262-2.483 3.993-4.955s-2.483-4.262-4.955-3.993a4.45 4.45 0 00-3.4 2.204L48.68 17.987a1.395 1.395 0 00-1.667 1.063v.022l-3.322 15.615a32.238 32.238 0 00-17.55 5.537 6.562 6.562 0 00-9.284.291 6.562 6.562 0 00.291 9.284 6.372 6.372 0 001.767 1.186c-.045.66-.045 1.32 0 1.98 0 10.078 11.745 18.277 26.23 18.277 14.485 0 26.23-8.188 26.23-18.277.045-.66.045-1.32 0-1.98A6.57 6.57 0 0075.011 45zm-45 4.508A4.517 4.517 0 0134.519 45c2.483 0 4.508 2.025 4.508 4.508s-2.025 4.508-4.508 4.508c-2.494-.023-4.508-2.025-4.508-4.508zm26.141 12.55v-.179a17.282 17.282 0 01-11.119 3.468 17.278 17.278 0 01-11.119-3.468 1.215 1.215 0 011.712-1.711 14.697 14.697 0 009.362 2.83 14.811 14.811 0 009.407-2.74 1.267 1.267 0 011.779.022c.481.492.47 1.297-.022 1.778zm-.615-7.718h-.224l.034-.168c-2.483 0-4.508-2.025-4.508-4.508s2.025-4.508 4.508-4.508 4.508 2.025 4.508 4.508a4.501 4.501 0 01-4.318 4.676z"
                      ></path>
                    </g>
                  </svg>
                </a>

                {/* <!--WHATSAPP ICON--> */}

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://api.whatsapp.com/send?text='${shareText ?? ''}'%20${
                    sharAbleLink ?? ''
                  }`}
                  data-action="share/whatsapp/share"
                  className="border hover:bg-[#25D366] w-12 h-12 fill-[#25D366] hover:fill-white border-green-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-green-500/50 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
                    ></path>
                  </svg>
                </a>

                {/* <!--LINKED ICON--> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${sharAbleLink ?? ''}`}
                  className="border hover:bg-[#229ED9] w-12 h-12 fill-[#229ED9] hover:fill-white border-sky-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="256"
                    height="256"
                    viewBox="0 0 256 256"
                  >
                    <g
                      fill="none"
                      strokeMiterlimit="10"
                      strokeWidth="0"
                      transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                    >
                      <path
                        fill="#069"
                        d="M45 0C20.147 0 0 20.147 0 45s20.147 45 45 45 45-20.147 45-45S69.853 0 45 0z"
                      ></path>
                      <rect
                        width="10.37"
                        height="33.34"
                        x="20.82"
                        y="36.62"
                        fill="#FFF"
                        rx="0"
                        ry="0"
                      ></rect>
                      <path
                        fill="#FFF"
                        d="M26.005 32.062A6.005 6.005 0 0120 26.055a6.007 6.007 0 016.005-6.011 6.01 6.01 0 016.005 6.011 6.01 6.01 0 01-6.005 6.007zM70 69.956H59.643V53.743c0-3.867-.067-8.84-5.385-8.84-5.392 0-6.215 4.215-6.215 8.562v16.491H37.686V36.617h9.939v4.559h.141c1.383-2.622 4.764-5.385 9.804-5.385 10.493 0 12.43 6.903 12.43 15.88v18.285z"
                      ></path>
                    </g>
                  </svg>
                </a>
              </div>

              <p className="text-sm">{t('Or copy link')}</p>
              {/* <!--BOX LINK--> */}
              <div className="border-2 border-gray-200 flex justify-between items-center mt-4 py-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-gray-500 ml-2"
                >
                  <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"></path>
                  <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"></path>
                </svg>

                <input
                  className="w-full outline-none bg-transparent"
                  type="text"
                  placeholder="link"
                  value={sharAbleLink}
                />

                <button
                  className="bg-primary text-white rounded text-sm py-2 px-5 mr-2 "
                  onClick={async () => {
                    await navigator.clipboard.writeText(sharAbleLink ?? '')
                    toast.success(t('Link Copied Successfully'))
                  }}
                >
                  {t('Copy')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ShareSocials
