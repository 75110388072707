import { sendGTMEvent } from '@next/third-parties/google'
interface AdBannerTrackerType {
  ad_id?: string
  ad_position: string
  ad_page: string
  ad_lang: string
  ad_clicked_id?:string
  ad_company_id:string
}
export const GTMAdBannerViewTracker = ({
  ad_id,
  ad_position,
  ad_lang,
  ad_page,
  ad_company_id,
}: AdBannerTrackerType) => {
  sendGTMEvent({
    event: 'adbanner_view',
    ad_id: ad_id,
    ad_position: ad_position,
    ad_lang: ad_lang,
    ad_page: ad_page,
    ad_company_id: ad_company_id,
  })
}

export const GTMAdBannerClickTracker = ({
  ad_clicked_id,
  ad_position,
  ad_lang,
  ad_page,
  ad_company_id,
}: AdBannerTrackerType) => {
  sendGTMEvent({
    event: 'adbanner_clicked',
    ad_clicked_id: ad_clicked_id,
    ad_position: ad_position,
    ad_lang: ad_lang,
    ad_page: ad_page,
    ad_company_id: ad_company_id,
  })
}
